import {app} from "../config";

const login = app.login
const register = app.register
const instagram = 'https://www.instagram.com/dss.group/'
const youtube = 'https://www.youtube.com/channel/UC04aAbcSxXldRtMAHCtH6XQ'
const telegram = 'https://teleg.run/forexsupport13'


export const redirectRoute = (route) => {
    window.location.href = route
}

export const goToLocation = {
    register: () => redirectRoute(register),
    login: () => redirectRoute(login),
    instagram: () => redirectRoute(instagram),
    youtube: () => redirectRoute(youtube),
    telegram: () => redirectRoute(telegram),
}
