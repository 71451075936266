import React from 'react';
import {useTranslation} from "react-i18next";
import Button from "../../components/Buttons/Button/Button";
import ConnectForm from "./ConnectForm/ConnectForm";
import HomeFooter from "./HomeFooter/HomeFooter";
import Menu from "../../components/Menu/Menu";
import DssImgPcSvg from '../../img/dssMain.svg';
import {goToLocation} from "../../utils/redirects";
import dssLogoMp4 from "../../video/dss_black.mp4";
import './Home.scss'


function Home() {
    const {t} = useTranslation()

    return (
        <div className='home'>
            <Menu />
            <div className='home-welcome'>
                <div className="home-welcome-container">
                  <video className="home-welcome-container-video" autoPlay loop muted playsInline id="entry-video">
                    <source src={dssLogoMp4} type="video/mp4" height={'100%'} width={'100%'} />
                  </video>
                </div>
                {/*<div className='home-welcome-text'>*/}
                {/*    <p className='home-welcome-text-title'>{t('home.title')}</p>*/}
                {/*    <Button className='home-welcome-text-button' onClick={() => goToLocation.register()}>*/}
                {/*        {t('home.connect')}*/}
                {/*    </Button>*/}
                {/*</div>*/}
                {/*<div className='home-welcome-img'>*/}
                {/*    <img className='home-welcome-img-svg' src={DssImgPcSvg} alt="dss"/>*/}
                {/*</div>*/}
            </div>
            <ConnectForm />
            <HomeFooter />
        </div>
    );
}

export default Home;
