import React from 'react';
import {colors} from "./colors";


function LoginSvg({className, color = colors.white}) {
    return (
        <div className={className}>
            <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.3521 10.3836C18.5477 10.1886 18.5482 9.87205 18.3532 9.67647L15.1765 6.4893C14.9815 6.29372 14.6649 6.2932 14.4693 6.48814C14.2738 6.68308 14.2733 6.99967 14.4682 7.19525L17.292 10.0283L14.459 12.8521C14.2634 13.047 14.2629 13.3636 14.4578 13.5592C14.6527 13.7548 14.9693 13.7553 15.1649 13.5604L18.3521 10.3836ZM-0.000861073 10.5001L17.9983 10.5294L17.9999 9.52944L0.000771599 9.50006L-0.000861073 10.5001Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.4999 10C24.4999 14.6944 20.6944 18.5 15.9999 18.5C12.7516 18.5 9.92888 16.6779 8.49805 14H6.83203C8.37514 17.5318 11.8993 20 15.9999 20C21.5228 20 25.9999 15.5228 25.9999 10C25.9999 4.47715 21.5228 0 15.9999 0C11.8993 0 8.37514 2.46819 6.83203 6H8.49805C9.92888 3.32211 12.7516 1.5 15.9999 1.5C20.6944 1.5 24.4999 5.30558 24.4999 10Z"
                    fill={color}
                />
            </svg>
        </div>
    );
}

export default LoginSvg;
