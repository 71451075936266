export const checkNavBarScroll = (setScrolled) => {
    window.onscroll = function() {

        // pageYOffset or scrollY
        if (window.pageYOffset > 0) {
            setScrolled(true)
        } else {
            setScrolled(false)
        }
    }
}
