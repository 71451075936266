import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import LoginSvg from "../../img/js/LoginSvg";
import LogoSvg from "../../img/js/LogoSvg";
import {checkNavBarScroll} from "../../utils/scroll";
import {colors} from "../../img/js/colors";
import {goToLocation} from "../../utils/redirects";
import './Menu.scss'


function Menu() {
    const {t} = useTranslation()
    const [scrolled, setScrolled] = useState()

    useEffect(() => checkNavBarScroll(setScrolled), [])

    return (
        <div className={`menu ${scrolled ? 'menu-scrolled' : ''}`}>
            <div className='menu-content'>
                <LogoSvg color={scrolled ? colors.blue : colors.white} />
                <div className='menu-content-action' onClick={() => goToLocation.login()}>
                    <LoginSvg className='menu-content-action-img' color={scrolled ? colors.gray : colors.white} />
                    <p className={`menu-content-action-login ${scrolled && 'menu-content-action-login-gray'}`}>
                        {t('menu.login')}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Menu;
