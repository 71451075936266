import parsePhoneNumberFromString from "libphonenumber-js";

export const isPhoneValid = (valuesList = [], t) => {
    const [phone] = valuesList

    if (phone) {
        const val = phone[0] !== '+' ? '+' + phone.toString() : phone
        if (val.toString().length < 8) return t('input.error.phone')
        const result = parsePhoneNumberFromString(val)

        return result && result.isPossible() && result.isValid() ? null : t('input.error.phone')
    }
}
