import React from 'react';
import {colors} from "./colors";

function LogoSvg({className, color = colors.white}) {
    return (
        <div className={className}>
            <svg width="109" height="53" viewBox="0 0 109 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_199_1394)">
                    <path d="M61.5994 12.1793V19.6541H64.4535C66.6965 19.6541 68.0174 18.2607 68.0174 15.8882C68.0174 13.5158 66.6965 12.1793 64.4535 12.1793H61.5994ZM63.1637 13.4691H64.2671C65.645 13.4691 66.422 14.329 66.422 15.8934C66.422 17.5096 65.6657 18.3591 64.2671 18.3591H63.1637V13.4691Z" fill={color}/>
                    <path d="M70.5919 19.6541V12.1793H69.0275V19.6541H70.5919Z" fill={color}/>
                    <path d="M78.5173 16.515V15.6706H75.3679V16.8103H76.9944L76.9892 16.9294C76.9581 17.8566 76.2485 18.4834 75.228 18.4834C73.9848 18.4834 73.1974 17.494 73.1974 15.8727C73.1974 14.2824 73.9382 13.3448 75.171 13.3448C76.0154 13.3448 76.6525 13.811 76.8804 14.5932H78.45C78.2272 13.0806 76.9115 12.0498 75.1658 12.0498C72.9695 12.0498 71.602 13.5002 71.602 15.8934C71.602 18.3228 72.9695 19.7784 75.2021 19.7784C77.2845 19.7784 78.5173 18.5507 78.5173 16.515Z" fill={color}/>
                    <path d="M81.1177 19.6541V12.1793H79.5533V19.6541H81.1177Z" fill={color}/>
                    <path d="M85.7124 19.6541V13.4691H87.8776V12.1793H81.9879V13.4691H84.148V19.6541H85.7124Z" fill={color}/>
                    <path d="M92.6225 19.6541H94.2853L91.685 12.1793H89.846L87.2508 19.6541H88.8567L89.4265 17.8411H92.0527L92.6225 19.6541ZM90.7215 13.609H90.7577L91.716 16.6704H89.7632L90.7215 13.609Z" fill={color}/>
                    <path d="M99.8849 18.3591H96.6163V12.1793H95.052V19.6541H99.8849V18.3591Z" fill={color}/>
                    <path d="M61.3145 28.1555C61.3611 29.5645 62.5473 30.414 64.3292 30.414C66.1992 30.414 67.344 29.492 67.344 28.0312C67.344 26.8968 66.6551 26.2596 65.0752 25.9437L64.2826 25.7831C63.402 25.607 63.0394 25.3221 63.0394 24.861C63.0394 24.3119 63.5574 23.9493 64.3292 23.9493C65.1166 23.9493 65.6709 24.3327 65.7227 24.9698H67.199C67.1731 23.5971 66.0749 22.6854 64.3292 22.6854C62.6664 22.6854 61.475 23.5919 61.475 24.9595C61.475 26.0732 62.2054 26.788 63.6973 27.0833L64.4846 27.2387C65.4222 27.4303 65.7745 27.71 65.7745 28.2021C65.7745 28.7512 65.2047 29.1553 64.3707 29.1553C63.5211 29.1553 62.8892 28.7823 62.7908 28.1555H61.3145Z" fill={color}/>
                    <path d="M76.2174 30.2897V22.8149H74.4044L72.2806 28.1814H72.2391L70.1153 22.8149H68.3023V30.2897H69.6957V25.2444H69.7372L71.7677 30.2897H72.752L74.7825 25.2444H74.824V30.2897H76.2174Z" fill={color}/>
                    <path d="M82.3557 30.2897H84.0185L81.4181 22.8149H79.5792L76.984 30.2897H78.5898L79.1596 28.4767H81.7859L82.3557 30.2897ZM80.4547 24.2446H80.4909L81.4492 27.306H79.4963L80.4547 24.2446Z" fill={color}/>
                    <path d="M86.3495 27.5546H87.6031L88.9965 30.2897H90.7681L89.1934 27.3215C90.0532 26.9848 90.5505 26.156 90.5505 25.1977C90.5505 23.7214 89.5663 22.8149 87.8465 22.8149H84.7852V30.2897H86.3495V27.5546ZM86.3495 24.0374H87.6808C88.4578 24.0374 88.9447 24.5036 88.9447 25.2288C88.9447 25.9696 88.4837 26.4202 87.6963 26.4202H86.3495V24.0374Z" fill={color}/>
                    <path d="M94.9328 30.2897V24.1047H97.0981V22.8149H91.2084V24.1047H93.3685V30.2897H94.9328Z" fill={color}/>
                    <path d="M61.3145 38.7911C61.3611 40.2001 62.5473 41.0496 64.3292 41.0496C66.1992 41.0496 67.344 40.1276 67.344 38.6668C67.344 37.5324 66.6551 36.8953 65.0752 36.5793L64.2826 36.4187C63.402 36.2426 63.0394 35.9577 63.0394 35.4966C63.0394 34.9476 63.5574 34.585 64.3292 34.585C65.1166 34.585 65.6709 34.9683 65.7227 35.6054H67.199C67.1731 34.2327 66.0749 33.321 64.3292 33.321C62.6664 33.321 61.475 34.2275 61.475 35.5951C61.475 36.7088 62.2054 37.4236 63.6973 37.7189L64.4846 37.8743C65.4222 38.0659 65.7745 38.3457 65.7745 38.8378C65.7745 39.3868 65.2047 39.7909 64.3707 39.7909C63.5211 39.7909 62.8892 39.4179 62.7908 38.7911H61.3145Z" fill={color}/>
                    <path d="M71.7833 40.9253V38.1333L74.3888 33.4505H72.7105L71.0322 36.6414H70.9959L69.3228 33.4505H67.6185L70.2189 38.1333V40.9253H71.7833Z" fill={color}/>
                    <path d="M74.6582 38.7911C74.7048 40.2001 75.8911 41.0496 77.673 41.0496C79.543 41.0496 80.6878 40.1276 80.6878 38.6668C80.6878 37.5324 79.9988 36.8953 78.4189 36.5793L77.6264 36.4187C76.7458 36.2426 76.3832 35.9577 76.3832 35.4966C76.3832 34.9476 76.9012 34.585 77.673 34.585C78.4603 34.585 79.0146 34.9683 79.0664 35.6054H80.5427C80.5168 34.2327 79.4186 33.321 77.673 33.321C76.0102 33.321 74.8188 34.2275 74.8188 35.5951C74.8188 36.7088 75.5492 37.4236 77.041 37.7189L77.8284 37.8743C78.766 38.0659 79.1182 38.3457 79.1182 38.8378C79.1182 39.3868 78.5484 39.7909 77.7144 39.7909C76.8649 39.7909 76.2329 39.4179 76.1345 38.7911H74.6582Z" fill={color}/>
                    <path d="M84.9975 40.9253V34.7404H87.1628V33.4505H81.2731V34.7404H83.4332V40.9253H84.9975Z" fill={color}/>
                    <path d="M92.98 39.6303H89.5922V37.7448H92.7883V36.543H89.5922V34.7404H92.98V33.4505H88.0279V40.9253H92.98V39.6303Z" fill={color}/>
                    <path d="M102.024 40.9253V33.4505H100.211L98.0875 38.817H98.046L95.9222 33.4505H94.1092V40.9253H95.5026V35.88H95.5441L97.5746 40.9253H98.5588L100.589 35.88H100.631V40.9253H102.024Z" fill={color}/>
                    <path d="M102.969 38.7911C103.016 40.2001 104.202 41.0496 105.984 41.0496C107.854 41.0496 108.999 40.1276 108.999 38.6668C108.999 37.5324 108.31 36.8953 106.73 36.5793L105.938 36.4187C105.057 36.2426 104.694 35.9577 104.694 35.4966C104.694 34.9476 105.212 34.585 105.984 34.585C106.772 34.585 107.326 34.9683 107.378 35.6054H108.854C108.828 34.2327 107.73 33.321 105.984 33.321C104.321 33.321 103.13 34.2275 103.13 35.5951C103.13 36.7088 103.86 37.4236 105.352 37.7189L106.14 37.8743C107.077 38.0659 107.429 38.3457 107.429 38.8378C107.429 39.3868 106.86 39.7909 106.026 39.7909C105.176 39.7909 104.544 39.4179 104.446 38.7911H102.969Z" fill={color}/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.7866 17.6672C8.95271 21.3328 8.02207 27.287 10.8687 32.0246C13.8121 36.9233 19.8 38.8668 24.9573 36.8891L29.6021 34.3053L34.7472 31.2138C34.9588 31.0867 35.1576 30.9462 35.3431 30.7943C37.221 29.2557 37.7363 26.5324 36.4489 24.3898C35.0344 22.0356 31.9793 21.2739 29.6251 22.6884L21.6684 27.4693C20.8837 27.9408 19.8653 27.6869 19.3938 26.9022C18.967 26.1919 19.1346 25.2901 19.7523 24.7758C19.8168 24.7221 19.8863 24.6726 19.9606 24.628L27.9173 19.8471C31.8409 17.4895 36.9327 18.7591 39.2902 22.6826C39.5027 23.0363 39.6858 23.3995 39.8401 23.7694C41.6252 27.4926 41.6742 31.6737 40.2372 35.3103C44.0516 31.6429 44.9728 25.7032 42.1319 20.9752C39.1115 15.9484 32.8853 14.0333 27.6405 16.2738L24.269 18.1681L24.269 18.1714L18.2534 21.7859C18.0921 21.8829 17.9383 21.9875 17.7921 22.0991C17.7049 22.1656 17.6205 22.2346 17.5389 22.3059C15.7584 23.8603 15.2918 26.5132 16.5517 28.61C17.9662 30.9641 21.0213 31.7259 23.3755 30.3113L31.3322 25.5305C32.1169 25.059 33.1353 25.3129 33.6068 26.0976C34.0203 26.7857 33.8759 27.6537 33.3048 28.1748C33.2782 28.199 33.2507 28.2225 33.2223 28.2452C33.1652 28.2908 33.1044 28.3331 33.0401 28.3718L25.0833 33.1527C21.1597 35.5102 16.0679 34.2407 13.7104 30.3171C13.5405 30.0344 13.3895 29.7457 13.2569 29.4523C11.3713 25.6635 11.3035 21.3785 12.7866 17.6672ZM24.2676 15.8873L27.5817 14.0253L27.58 11.3755L27.5781 8.44782L25.0686 9.95568L20.1169 12.931L20.119 12.9345C17.2417 14.6633 15.3683 17.4266 14.6888 20.4626C15.2179 19.8829 15.8388 19.3692 16.546 18.9443L24.2666 14.3053L24.2676 15.8873ZM25.4206 41.6243L25.4189 38.9085L28.733 37.065L28.734 38.6945L36.4546 34.0555C37.1672 33.6274 37.7922 33.109 38.3239 32.5239C37.7019 35.3523 36.0447 37.9475 33.5054 39.6952L27.932 43.0441L25.4225 44.552L25.4206 41.6243Z" fill={color}/>
                    <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M0.16018 23.38C1.58444 11.226 11.2265 1.58398 23.3805 0.15979L20.4285 4.09586C12.4922 6.24153 6.24202 12.4917 4.09628 20.4279L0.16018 23.38ZM0.160156 29.6197C1.58434 41.7738 11.2265 51.416 23.3806 52.8402L20.4285 48.9041C12.4921 46.7584 6.2419 40.5082 4.09622 32.5718L0.160156 29.6197ZM29.6202 52.8401C41.7743 51.4159 51.4163 41.7738 52.8405 29.6198L48.9045 32.5718C46.7588 40.5082 40.5086 46.7584 32.5723 48.9041L29.6202 52.8401ZM29.6202 0.159798C41.7742 1.58402 51.4162 11.226 52.8405 23.38L48.9044 20.4279C46.7587 12.4917 40.5085 6.24157 32.5723 4.09588L29.6202 0.159798Z" fill={color}/>
                </g>
                <defs>
                    <clipPath id="clip0_199_1394">
                        <rect width="109" height="53" fill={color}/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}

export default LogoSvg;
