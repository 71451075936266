import React from 'react';
import LogoSvg from "../../../img/js/LogoSvg";
import instagramSvg from '../../../img/social/instagram.svg'
import youtubeSvg from '../../../img/social/youtube.svg'
import tgSvg from '../../../img/social/tg.svg'
import {goToLocation} from "../../../utils/redirects";
import './HomeFooter.scss'


function HomeFooter() {
    return (
        <div className='home-footer'>
            <div className='home-footer-wrapper'>
                <LogoSvg />
                <div className='home-footer-wrapper-social'>
                    <img
                        onClick={() => goToLocation.instagram()}
                        className='home-footer-wrapper-social-instagram'
                        src={instagramSvg}
                        alt='instagram'
                    />
                    <img
                        onClick={() => goToLocation.youtube()}
                        className='home-footer-wrapper-social-youtube'
                        src={youtubeSvg}
                        alt='youtube'
                    />
                    <img
                        onClick={() => goToLocation.telegram()}
                        className='home-footer-wrapper-social-telegram'
                        src={tgSvg}
                        alt='telegram'
                    />
                </div>
            </div>
        </div>
    );
}

export default HomeFooter;
