import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translation/en";
import ru from "./translation/ru";
import {getLanguage} from "../store/localStore";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en
            },
            ru: {
                translation: ru
            },
        },
        lng: getLanguage() || 'ru',
        fallbackLng: "ru",

        interpolation: {
            escapeValue: false
        }
    }).then(() => {});

export default i18n