import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Input from "../../../components/Inputs/Input/Input";
import Button from "../../../components/Buttons/Button/Button"
import dssImgSvg from '../../../img/dssImg.svg'
import {formatPhone} from "../../../utils/format";
import {isPhoneValid} from "../../../style/validator";
import './ConnectForm.scss'


function ConnectForm() {
    const {t} = useTranslation()
    const [phone, setPhone] = useState('')
    const [errorPhone, setErrorPhone] = useState('')

    const send = () => {
        console.log('send it for phone: ', phone)
    }

    return (
        <div className='connect-form'>
            <div className='connect-form-form'>
                <div className='connect-form-form-wrapper'>
                    <p className='connect-form-form-wrapper-title'>{t('home.create')}</p>
                    <div className='connect-form-form-wrapper-form'>
                        <Input
                            className='connect-form-form-wrapper-form-phone'
                            placeholder={t('home.form.phone')}
                            value={phone}
                            error={errorPhone}
                            onChange={(e) => setPhone(formatPhone(e.target.value))}
                            onError={(value) => setErrorPhone(isPhoneValid([value], t))}
                        />
                        <Button
                            className='connect-form-form-wrapper-form-button'
                            disabled={errorPhone || !phone}
                            onClick={send}
                        >
                            {t('home.send')}
                        </Button>
                    </div>
                </div>
                <div className='connect-form-form-img'>
                    <img className='connect-form-form-img-svg' src={dssImgSvg} alt="dss-img"/>
                </div>
            </div>
        </div>
    );
}

export default ConnectForm;
