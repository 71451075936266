import React from 'react';
import './Button.scss'


function Button({className, onClick, children, disabled}) {
    const triggerClick = () => {
        if (disabled) {
            return
        }

        onClick()
    }

    return (
        <button className={`button ${className || ''} ${disabled ? 'button-disabled' : ''}`} onClick={triggerClick}>
            {children}
        </button>
    );
}

export default Button;
