const app = {
    env: process.env.REACT_APP_ENV,
    login: process.env.REACT_APP_LOGIN_ROUTE,
    register: process.env.REACT_APP_REGISTER_ROUTE,
}

if (app.env === 'local') {
    console.log('application config settings: ', app)
}

export {app}
